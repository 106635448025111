//
// plyr
//

video{
    object-fit: inherit;
}

.video-plyr-container {
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.plyr__video-wrapper{
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
}


.plyr__control--overlaid{
    border-radius: 0px;
    padding: 2rem;
    background-color: $primary;

    &:hover {
        background-color: $secoundary;
    }
}

.plyr__controls{
    opacity: 0;;
}

.plyr--playing .plyr__controls{
    opacity: 1;
}

.plyr__control--overlaid svg{
    display: none;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid{
    width: 65px;
    height: 65px;
    background-position: 50%;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: 25px 22px;
    background-image: url(../img/plyr-arrow.png);
}

@include media-breakpoint-down(sm) {
    .video-plyr-container {
        margin-left: 0px;
        margin-right: 0px;
       
    }
}


