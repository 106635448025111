// 
// Footer
// 
.footer {
    position: relative;
    z-index: 2;
    background-color: $white;
    border-top: 1px solid $gray-300;
    .footer-row {
        display: flex;
    }
    .footer-brand {
        flex: 0 1 auto;
        padding: 15px 50px 40px 0px;
        flex: 0 1 auto;
        img {
            max-width: 290px;
        }
        .footer-data {
            padding: 20px 40px 20px 70px;
            p {
                margin-bottom: 0px;
                font-size: 14px;
            }
        }
    }
    .footer-main {
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        border-left: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
        padding: 45px 50px;
        .footer-menu-item {
            flex: 0 0 230px;
        }
        .footer-menu-main {
            margin-left: 30px;
            margin-right: 80px;
            padding-right: 10px;
            border-right: 1px solid $gray-300;
            li {
                margin-bottom: 10px;
            }
            a {
                font-family: 'Asap', sans-serif;
                font-weight: 700;
                font-size: 16px;
                display: block;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    right: -11px;
                    top: 0;
                    bottom: 0;
                    background-color: transparent;
                    width: 1px;
                }
            }
            li.active a {
                color: $secoundary;
                &:after {
                    background-color: $secoundary;
                }
            }
        }
        .footer-menu-sub {
            padding-right: 10px;
            li {
                margin-bottom: 7px;
            }
            a {
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
    .footer-cobis {
        flex: 0 1 auto;
        padding: 75px 0px 40px 50px;
        img {
            max-width: 285px;
        }
    }

    .footer-menu[data-category]{
        display: none;
    }
    .footer-menu[data-category].active{
        display: block;
    }

    .copyright{
        padding: 20px 5px 5px 5px;
        font-size: 14px;
        p{
            margin: 0;
        }
    }
}

@include media-breakpoint-down(xl) {
    .footer {
        .footer-brand {
            padding: 15px 40px 40px 0px;
            img {
                max-width: 220px;
            }
            .footer-data {
                padding: 20px 40px 20px 55px;
            }
        }
        .footer-main {
            padding: 35px 40px;
            .footer-menu-item {
                flex: 0 0 205px;
            }

            .footer-menu-main{
                margin-right: 50px;
            }
        }
        .footer-cobis {
            padding: 75px 0px 40px 40px;
            img {
                max-width: 205px;
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .footer {
        .container{
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            .row{
                margin-left: 0;
                margin-right: 0;
                [class^="col-"]{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .footer-brand, .footer-main, .footer-cobis{
            flex: 1 1 33.333%;
            padding: 25px;
        }
        .footer-brand {
            .footer-data{
                padding-right: 0;
                padding-left: 10px;
            }
            img {
                max-width: 90%;
            }
        }
        .footer-main {
            padding-top: 45px;
            .footer-menu-item {
                flex: 1 1 100%;
            }
            .footer-menu-main{
                flex: 1 1 100%;
                margin: 0;
                border:0px;

                a{
                    text-align: center;
                }
                
                a:after{
                    content: none;
                }
            }

            .footer-menu-sub{
                display: none;
            }
        }
        .footer-cobis {
            img {
                max-width: 90%;
            }
        }
    }

    .footer .footer-main .footer-menu-main li.active a{
        color: $primary;
    }
}


@include media-breakpoint-down(sm) {
    .footer {
        .footer-row{
            flex-wrap: wrap;
        }
        .footer-brand, .footer-main, .footer-cobis{
            flex: 1 1 100%;
            text-align: center;
        }
        .footer-brand {
            .footer-data{
                padding-left: 10px;
            }
            img {
                max-width: 90%;
            }
        }
        .footer-main {
            border-top: 1px solid $gray-300;
            border-bottom: 1px solid $gray-300;
            border-left: 0px;
            border-right: 0px;
            padding-top: 25px;
            .footer-menu-item {
                flex: 1 1 100%;
            }
            .footer-menu-main{
                flex: 1 1 100%;
                margin: 0;
                border:0px;
            }
        }
        .footer-cobis {
            img {
                max-width: 90%;
            }
        }
    }
}