// article gallery
.gallery-container{
    position: relative;
    overflow: hidden;
    margin: 30px 0;
    padding: 60px 0;

    .swiper-nav{
        width: 60px;
        height: 65px;
        position: absolute;
        z-index: 55;
        top: 50%;
        transform: translateY(-50%);
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
    }
    .swiper-nav-prev{
        left: 10px;
    }
    .swiper-nav-next{
        right: 10px;
    }
    
    .swiper-slide{
        transform: scale(1);
        transition: all 0.4s;
        transform-origin: 50%;
        padding: 40px 0;
        .overlay:after{
            background-color: rgba($primary, 0.5);
            opacity: 1;
        }
    
        .overlay:before{
            content: "\e90f";
            font-family: 'icomoon' !important;
            color: $white;
            font-size: 22px;
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 5;
            opacity: 0;
            transition: all 0.3s;
        }
    }
    
    .swiper-slide-active{
        transform: scale(1.3);
        z-index: 44;
        .overlay:after{
            opacity: 0;
        }
        .overlay:before{
            opacity: 1;
        }
    }
}


@include media-breakpoint-down(sm) {
    .gallery-container{
        margin: 0px 0;
        padding: 0px 0;
    
        .swiper-nav{
            width: 30px;
            height: 30px;
            font-size: 8px;
        }

        .swiper-slide{
            padding: 30px 0;
            .overlay:before{
                font-size: 12px;
                right: 5px;
                top: 5px;
            }
        }
    }
}

