// 
// Google map
// 
.g-map {
    position: relative;
    overflow: hidden;
    padding-bottom: 62.5em;
    .g-map-canvas {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray-100;
    }
}

.g-map-static {
    img {
        display: block;
        width: 100%;
    }
}