// 
// page hero
// 

.page-hero {
    position: relative;
    overflow: hidden;
    padding-bottom: 32.5%;
    background-color: #061259;

    &-mask{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(#061259, 0.5);
        }
    }

    &-image{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        opacity: 0.6;
        filter: grayscale(100%);
    }
    &-title{
        position: absolute;
        left: 0;
        right: 0;
        bottom: -30px;
        color: $white;
        font-size: 220px;
        line-height: 1;
        white-space: nowrap;
    }

    &-description {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 220px 0px 80px 0px;
        color: $white;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
            color: $white;
            font-family: 'Playfair Display', serif;
            font-weight: 400;
            margin-bottom: 40px;
        }

        h5, .h5, h6, .h6, p {
            font-family: 'Asap', sans-serif;
            color: #BCBAB8
        }

        
    }
}

@include media-breakpoint-down(xl) {
    .page-hero{
        &-title{
            bottom: -20px;
            font-size: 180px;
        }
    }
}


@include media-breakpoint-down(lg) {
    .page-hero {
        padding-bottom: 42.5%;
        &-title{
            bottom: -20px;
            font-size: 110px;
        }

        &-description {
            padding: 110px 0px 0px 0px;
            h1, .h1 {
                margin-bottom: 20px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .page-hero{
        padding-bottom: 52.5%;
        &-title {
            bottom: -6px;
            font-size: 44px;
        }
    }

    
}