// 
// callout
// 

.callout{
    position: relative;
    overflow: hidden;
    .callout-bg{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;

        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba( $dark, 0.1 );
            transition: all 0.35s;
        }
    }

    .contact-form{
        margin: 0;
    }

    .callout-content{
        padding: 40px 0;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 640px;

        h2{
            text-transform: uppercase;
            font-weight: 900;
            color: $primary;
        }
        h3{
            font-weight: 100;
        }

        a{
            color: $white;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 2px;
                background-color: $gray-300;
            }
            &:hover{
                color: $primary;
            }
        }
    }

    .callout-main{
       display: block;
    }

    .callout-form{
       display: none;
    }

    
    .callout-close{
        text-align: right;
        margin-bottom: 15px;
        color: $white;
        cursor: pointer;
        transition: all 0.3s;
        &:hover{
            color: $primary;
        }
    }
}


.callout.show-form{
    .callout-form{
       display: block;
    }

    .callout-main{
        display: none;
    }
    
    .callout-bg{
        &:after{
           background-color: rgba( $dark, 0.85 );
        }
    }
}

@include media-breakpoint-down(md) {
    .callout{
        .callout-content{
            padding: 70px 0;
            min-height: auto;
            h3{
                font-size: 20px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .callout{
        margin-top: 40px;
        .callout-bg{
            &:after{
               background-color: rgba( $dark, 0.6 );
            }
        }
        
        .callout-content{
            padding: 70px 0;

            h3{
                font-size: 20px;
            }
        }
    }

    .callout-show{
        .callout-bg{
            &:after{
               background-color: rgba( $dark, 1 );
            }
        }
    }
}


@media only screen and (min-width: 3000px) {
    .callout{
        .callout-content{
            min-height: 18vw;
        }
    }
}