// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);


$tooltip-color:               $black;
$tooltip-bg:                  $white;
$tooltip-arrow-color:         $tooltip-bg;

// Colors
//
//
$primary:       #244473;
$secoundary:    #54c9f6;
$dark:          #224573;
$body-color:    #636363;