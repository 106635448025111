// 
// page: contact
// 
.page-contact {
    .article-content {
        padding-left: 0;
        padding-right: 0;
    }
    .article-title:after {
        content: none;
    }
    .page-content-container {
        display: block;
    }
    .page-main {
        width: 100%;
    }
}

.contact-data-page{
    padding: 0px 130px 0px 200px
}

.contact-form {
    padding-top: 70px;
    margin-bottom: 50px;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: -10px;
        right: -10px;
        top: 0;
        background-color: $gray-300;
        height: 1px;
    }
    h5 {
        margin-bottom: 40px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: -200px;
            top: 15px;
            width: 100px;
            height: 1px;
            background-color: rgb(219, 134, 29);
        }
    }
}

.contact-data-row {
    margin-left: -110px;
    display: flex;
    padding: 40px 0;
    .contact-data-col {
        flex: 0 0 50%;
        padding: 20px 110px;
    }
    .contact-data-col:first-of-type {
        border-right: 1px solid $gray-300;
    }
    h5 {
        margin-bottom: 40px;
    }
}

.data-row-bg {
    background-color: $gray-100;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: -80px;
        top: 72px;
        width: 100px;
        height: 1px;
        background-color: rgb(219, 134, 29);
    }
}

.contact-phone {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 40px 0;
    padding-bottom: 20px;
    p,
    h6 {
        margin: 0;
    }
    p {
        margin-right: 12px;
    }
}


@include media-breakpoint-down(lg) {
    .contact-data-page{
        padding: 0px 0px 0px 150px
    }

    .data-row-bg {
        &:before {
            left: -30px;
        }
    }

    .contact-form {
        h5 {
           &:before {
                left: -140px;
            }
        }
    }

    .contact-data-row h5{
        margin-bottom: 30px;
    }
}


@include media-breakpoint-down(md) {
    .contact-data-page{
        padding: 0px;
    }

    .data-row-bg, .contact-form {
        &:before {
            content: none;
        }
    }

    .contact-data-row .contact-data-col{
        padding: 20px 15px;
    }

    .contact-data-row{
        margin-left: -15px;
        margin-right: -15px;
    }
}


@include media-breakpoint-down(sm) {
    .contact-data-row{
        display: block;
    }

    .contact-data-row .contact-data-col:first-of-type{
        border-right: 0px;
        border-bottom: 1px solid $gray-300;
    }

    .contact-form{
        padding-top: 0;
    }
}