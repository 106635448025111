// 
// Offer list
// 

ul.list-offer{
    margin-bottom: 80px;
    > li{
        padding: 80px 0;
    }
}

.offer-item{
    .offer-item-flex{
        display: flex;
    }
    .offer-image{
        width: 50%;
        flex: 0 0 50%;
        position: relative;
    }
    .offer-content{
        width: 50%;
        flex: 0 0 50%;
    }
    .offer-content-inner{
        padding: 0 20%;
        p{
            margin-bottom: 40px;
        }
    }
    .offer-title{
        h2{
            color: $primary;
            font-weight: 900;
            text-transform: uppercase;
        }
    }
    .offer-letter{
        position: absolute;
        color: $primary;
        font-size: 500px;
        text-transform: uppercase;
        line-height: 1;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        span{
            line-height: 1
        }
    }
}

.offer-item-left{
    .offer-title{
        min-height: 130px;
        margin: 60px 0px 20px 0px;
        padding-left: 40px;
    }
    
    .offer-image{
        padding-left: 10%;
    }
    .offer-letter{
        left: 0;
        bottom: -30%;
    }
}

.offer-item-right{
    .offer-title{
        margin: 60px 0px;
        padding-left: 40px;
    }
    .offer-image{
        padding-right: 10%;
    }
    .offer-letter{
        right: 0;
        bottom: -30%;
    }
    .offer-content-inner{
        padding-top: 18%;
    }
}

// 
// offer bear
// 
ul.list-offer-bear{
    > li{
        margin: 80px 0;
    }
}
.offer-item-bear{
    display: flex;
    .offer-image{
        flex: 0 0 40%;
        padding-left: 8.33333%;
        position: relative;
    }
    .offer-content{
        flex: 0 0 60%;
        padding: 0 12%;
        p{
            margin-bottom: 20px;
            font-size: 16px;
        }
        .lead{
            margin-bottom: 30px;
            font-size: 18px;
        }
    }
}


@supports (mix-blend-mode: lighten) {
    .offer-item-bear{
        .offer-image{
            &:before{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: #100f0f;
                mix-blend-mode: lighten;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    //offer
    .offer-item{
        .offer-letter{
            font-size: 400px;
            bottom: -15%;
        }
    }
}

@include media-breakpoint-down(lg) {
    // offer
    .offer-item{
        .offer-letter{
            font-size: 350px;
            bottom: 0%;
        }
    }
    // offer bear
    ul.list-offer-bear{
        > li{
            margin: 50px 0;
        }
    }
    .offer-item-bear{
        .offer-image{
            padding-left: 0%;
        }
        .offer-content{
            padding: 0 6%;
        }
    }
}

@include media-breakpoint-down(md) {
    // offer
    .offer-item{
        .offer-content-inner{
            padding: 0;
        }
        .offer-letter{
            display: none;
        }
    }
    .offer-item-left .offer-title, .offer-item-right .offer-title{
        padding: 0px;
        margin-top: 0;
        min-height: auto;
    }
}

@include media-breakpoint-down(sm) {
    // offer
    ul.list-offer > li{
        padding: 40px 0;
    }
    .offer-item{
        .offer-item-flex{
            flex-wrap: wrap;
        }
        .offer-content, .offer-image{
            width: 100%;
            flex: 1 1 100%;
        }
        .offer-content-inner p{
            margin: 25px 0;
        }

        .offer-title{
            order: 1;
        }
        .offer-image{
            display: none;
        }
        .offer-content{
            order: 2;
        }
    }

    .offer-item-left .offer-title, .offer-item-right .offer-title{
        margin: 0;
        margin-bottom: 20px;
    }
    
    // offer bear
    ul.list-offer-bear{
        > li{
            margin: 30px 0;
        }
    }
    .offer-item-bear{
        display: block;
        .offer-image{
            padding: 10px 25%;
            text-align: center;
        }
        .offer-content{
            padding: 0;
            p{
                margin-bottom: 10px;
            }
            .lead{
                margin-bottom: 20px;
            }
        }
    }
}