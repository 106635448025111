// 
// Sidebar
// 

.sidebar-triger-container{
    display: none;
    height: 50px;
    position: relative;

    .sidebar-trigger{
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 14px;
        color: $dark;
        font-weight: 700;
        display: none;
        [class^="icon"]{
            font-size: 16px;
            margin-left: 6px;
            line-height: 1;
        }
    }
}
.sidebar {
    padding: 0px 20px;
    padding-right: 0;
    border-left: 1px solid $gray-200;
    margin-left: 20px;
    .sidebar-close{
        position: absolute;
        right: 28px;
        top: 65px;
        display: none;
    }
    &-widget{

    }
    &-title{
        padding: 30px 20px;
        padding-right: 0;
    }
    &-content{

    }
    &-menu{
        padding: 0 20px;
        li{
            margin: 4px 0;
        }
        a{
            font-family: 'Asap', sans-serif;
            font-size: 14px;
            display: block;
            padding: 10px 0px;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 1px;
                width: 0px;
                background-color: $secoundary;
                transition: all 0.3s;
            }
        }
        a:hover{
            color: $secoundary;
        }
        a.active{
            color: $secoundary;
            &:after{
                width: 60px;
            }
        }
    }

    &-news-list{
        > li{
            margin-bottom: 5px;
        }
        .news-list-content{
            padding: 10px 20px;
        }
        h6{
            font-size: 13px;
            font-weight: 400;
            opacity: 0.7;
            margin-bottom: 8px;
        }
        p{
            font-size: 13px;
            margin: 0;
        }
    }
    .sidebar-category-back{
        padding-left: 20px;
        font-size: 8px;
        color: $gray-300;
        &:hover{
            color: $secoundary;
        }
    }
}


// 
// Recent posts
// 
ul.recent-posts {
    li {
        padding: 10px 0;
    }
}

.recent-item {
    display: flex;
    .recent-image {
        width: 80px;
        flex: 0 0 80px;
    }
    .recent-content {
        padding: 5px 15px;
        padding-right: 0;
        padding-top: 3px;
        h6,
        p {
            transition: color 0.3s;
        }
        h6 {
            font-size: 12px;
            font-weight: 400;
            color: $gray-300;
            margin-bottom: 5px;
        }
        p {
            font-size: 13px;
            margin: 0;
            color: $white;
        }
    }
}

.recent-item:hover {
    h6,
    p {
        color: $primary;
    }
}


@include media-breakpoint-down(xl) {
    .sidebar {
        &-title{
            padding: 20px 20px;
            padding-right: 0;
        }
        &-menu{
            a{
                padding: 8px 0px;
            }
        }
    }
}


.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    background: rgba($dark, 0.4);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.sidebar-is-open{
    .sidebar-overlay{
        opacity: 1;
        visibility: visible;
    }
}


@include media-breakpoint-down(md) {
    .sidebar-triger-container{
        display: block;
    }
    .sidebar{
        padding-top: 70px;
        position: fixed;
        z-index: 999;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        width: 240px;
        max-width: 90%;
        background-color: $white;
        transform: translate3d(100%, 0, 0);
        transition: all 0.3s;
        .sidebar-close{
            display: block;
        }
    }

    .sidebar.show{
        transform: translate3d(0%, 0, 0);
    }

    .sidebar-triger-container{
        .sidebar-trigger.show{
            display: block;
        }
    }
}
