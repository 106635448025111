// 
// page: home
// 

.section{
    overflow: hidden;
}

.image-pull-7{
    width:  55vw;
    max-width: 1100px;
}


// swiper hero
.page-home {
    padding-top: 100vh;
}

.page-hero-home {
    padding-bottom: 0;
}

.slide-hero {
    .slide-hero-image {
        opacity: 0.6;
        position: relative;
    }
    .image-holder {
        padding: 0;
        height: 100vh;
        filter: grayscale(100%);
    }
    .swiper-slide {
        padding: 0;
    }
    .slide-hero-mask{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(#061259, 0.5);
        }
    }
    .slide-hero-content {
        position: absolute;
        z-index: 44;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        h2 {
            color: $white;
            font-family: 'Playfair Display', serif;
            font-size: 80px;
            font-weight: 400;
        }
        h3 {
            color: $white;
            font-size: 32px;
            font-weight: 400;
            strong {
                font-weight: 700;
                color: $secoundary;
            }
        }
    }
}

#swiper-hero {
    position: relative;

    .swiper-slide{
        h2, h3{
            opacity: 0;
            transform: translateY(40px);
            transition: all 0.45s;
        }
    }

    .swiper-slide-active{
       h2, h3{
           opacity: 1;
           transform: translateY(0px);
       }
    }
    .swiper-hero-logo {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        z-index: 55;
        img{
            max-width: 15vw;
        }
    }
    .swiper-hero-scroll {
        position: absolute;
        z-index: 55;
        bottom: 30px;
        left: 50%;
        transform: translateY(-50%);
        span {
            font-family: 'Asap', sans-serif;
            font-size: 14px;
            margin-bottom: 4px;
            color: $white;
            display: block;
            margin-bottom: 10px;
            transition: all 0.25s;
        }
        i {
            display: block;
            color: $white;
            text-align: center;
            font-size: 64px;
            opacity: 0.65;
            position: relative;
            transition: all 0.25s;
            &:after{
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                height: 20px;
                width: 1px;
                margin-left: -1px;
                background-color: $secoundary;
            }
        }

        &:hover{
            span, i{
                color: $secoundary;
            }
            i:after{
                content: none;
            }
        }
    }
}

// section home about
.section-home-about {
    padding: 90px 0;
    .about-conteiner {
        display: flex;
        .about-image {
            flex: 0 0 315px;
            .image-holder {
                border-radius: 50%;
                .image-holder-bg {
                    background-position: top center;
                }
            }
        }
        .about-content {
            flex: 1 1 auto;
            padding-left: 40px;
            h2 {
                font-family: 'Playfair Display', serif;
                font-weight: 400;
                margin-bottom: 55px;
            }
            p {
                margin-bottom: 25px;
            }
            h5 {
                font-size: 26px;
                color: $secoundary;
                margin-bottom: 50px;
            }
        }
        .about-signature {
            margin-top: 55px;
            text-align: right;
            h6 {
                margin: 0;
                margin-top: 10px;
                font-size: 22px;
                color: $primary;
            }
        }
    }
}

// parallax
.parallax {
    background-color: #061259;
    position: relative;
    overflow: hidden;
    .parallax-image {
        position: absolute;
        z-index: 11;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
        background-attachment: fixed;
        opacity: 0.6;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(#01072b, 0.5);
        }
    }
    .parallax-content {
        position: relative;
        z-index: 22;
    }
}

// section why bsw
.parallax-bsw {
    .parallax-content {
        padding: 200px 0 140px 0px;
    }
    .parallax-image{
        &:before{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(to right, $primary 0%,transparent 100%);
        }
    }
    h2 {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        margin-bottom: 10px;
        color: $white;
    }
    h3 {
        color: $white;
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 40px;
        strong {
            font-weight: 700;
            color: $secoundary;
        }
    }
    .link-more {
        color: $white;
        &:hover {
            color: $secoundary;
        }
    }
}

ul.why-bsw-grid {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    >li {
        flex: 0 0 25%;
    }
    .why-bsw-item {
        display: block;
        position: relative;
        overflow: hidden;
        .image-holder {
            padding-bottom: 120%;
        }
        .title {
            position: absolute;
            z-index: 22;
            padding-left: 50px;
            padding-top: 40px;
            left: 0;
            right: 0;
            top: 0px;
            color: $white;
            transform: translateY(0%);
            transition: all 0.35s;
        }
        .hover-content {
            padding: 30px 50px;
            position: absolute;
            z-index: 22;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($primary, 0.85);
            transform: translateY(100%);
            transition: all 0.35s;
            h5 {
                color: $white;
            }
            .link-more {
                color: $secoundary;
            }
        }
    }
    .why-bsw-item:hover {
        .title {
            transform: translateY(-100%);
        }
        .hover-content {
            transform: translateY(0%);
        }
    }
}

// section values
.section-values {
    padding: 140px 0;
    h2 {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        margin-bottom: 60px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: -110px;
            bottom: 5px;
            width: 100px;
            height: 1px;
            background-color: $secoundary;
        }
    }
    h5 {
        color: $secoundary;
        max-width: 520px;
    }
    .values-container {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: -60px;
            right: -60px;
            top: -60px;
            bottom: 8px;
            border: 1px solid $gray-300;
        }
    }
    ul.values-grid {
        padding: 0;
        margin: 0;
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        >li {
            flex: 0 0 20%;
            text-align: center;
            padding: 10px;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 95px;
                width: 1px;
                background-color: $gray-300;
            }
        }
        li:last-child {
            &:after {
                content: none;
            }
        }
        .values-item {
            display: block;
            padding: 66px 5px 30px 5px;
            background-color: $white;
            transition: all 0.3s;
            overflow: hidden;
        }
        
        .values-title {
            font-size: 20px;
            font-weight: 700;
            color: $primary;
            font-family: 'Asap', sans-serif;
            margin-bottom: 25px;
        }
        .values-icon {
            font-size: 90px;
        }
        .values-text{
            padding: 25px 20px;
            opacity: 0;
            transform: translateY(60px);
            transition: all 0.3s;
        }

        .values-item:hover {
            background-color: $gray-100;
            .values-text{
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}

// section news
.parallax-news{
    .parallax-image {
        opacity: 0.5;
        &:after {
            background-color: rgba(#01072b, 0.7);
        }
    }
}
.section-news {
    .section-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 100px 0;
        a {
            color: $white;
            display: flex;
            align-items: center;
            font-size: 14px;
            [class^="icon"] {
                font-size: 12px;
                margin-right: 8px;
                font-family: 'Asap', sans-serif;
            }
            &:hover{
                color: $secoundary;
            }
        }
        h2 {
            font-family: 'Playfair Display', serif;
            font-weight: 400;
            margin-bottom: 0px;
            color: $white;
        }
    }
    ul.news-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        >li {
            flex: 0 0 25%;
            border: 1px solid transparent;
            padding: 30px 30px 40px 30px;
            border-bottom: 0px;
            position: relative;
            transition: all 0.3s;
            &:before,
            &:after {
                content: "";
                height: 1px;
                width: calc( 50% - 5px);
                position: absolute;
                bottom: 0;
                background-color: transparent;
                transition: all 0.3s;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
            .li-tr {
                position: absolute;
                width: 10px;
                height: 10px;
                border-left: 1px solid transparent;
                border-top: 1px solid transparent;
                bottom: -5px;
                transform: rotate(-135deg);
                left: 50%;
                margin-left: -5px;
                transition: all 0.3s;
            }
        }
        .news-list-item {
            .news-meta {
                font-family: 'Asap', sans-serif;
                color: rgba($white, 0.4);
                font-size: 13px;
            }
            .news-title {
                font-size: 18px;
                color: $white;
                font-family: 'Asap', sans-serif;
                font-weight: 400;
                position: relative;
                padding-bottom: 15px;
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background-color: rgba($white, 0.4);
                    height: 1px;
                    width: 58px;
                }
                &:after {
                    width: 20px;
                    transition: all 0.3s;
                    background-color: $secoundary;
                }
            }
        }
    }
    ul.news-list>li.active {
        border-color: #f8faff;
        &:before,
        &:after {
            background-color: #f8faff;
        }
        .li-tr {
            border-color: #f8faff;
        }
        .news-list-item .news-title {
            color: $secoundary;
        }
        .news-list-item .news-title:after {
            width: 58px;
        }
    }
    .news-swiper-container {
        padding-top: 60px;
        padding-bottom: 60px;
        .swiper-slide-news {
            opacity: 0;
            transform: translateY(200px);
            display: flex;
            align-items: center;
            transition: all 0.4s;
            .image {
                flex: 0 0 40%;
            }
            .content {
                flex: 0 0 60%;
                padding: 10%;
            }
            .news-meta {
                font-family: 'Asap', sans-serif;
                color: $white;
                font-size: 13px;
                margin-bottom: 0;
            }
            h2 {
                font-family: 'Asap', sans-serif;
                color: $white;
                font-weight: 700;
                margin-bottom: 30px;
                line-height: 1.2;
                margin-top: 0;
            }
            p {
                color: $white;
            }
            a.link-more {
                color: $white;
            }
        }
    }

    .swiper-slide-active{
        .swiper-slide-news{
            opacity: 1;
            transform: translateY(0px);
        }
    }
}


// section contact
.section-contact{
    padding: 100px 0;
    h2 {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        margin-bottom: 60px;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            left: -110px;
            bottom: 5px;
            width: 100px;
            height: 1px;
            background-color: #db861d;
        }
    }
    h5{
        color: $secoundary;
        margin-bottom: 50px;
    }
    .contact-data-group + .contact-data-group{
        margin-top: 40px;
    }
    .contact-data-group{
        p{
            margin: 0;
        }
    }
}

.section-video {
    padding-bottom: 100px;
    h2 {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        margin-bottom: 10px;
        position: relative;
    }
    h5{
        color: $secoundary;
        margin-bottom: 40px;
    }
}


@include media-breakpoint-down(xl) {
    
    // swiper hero
    .slide-hero {
        .slide-hero-content {
            h2 {
                font-size: 48px;
            }
            h3 {
                font-size: 26px;
            }
        }
    }

    #swiper-hero{
        .swiper-hero-scroll{
            transform: translateY(0px);
            bottom: 20px;
        }
        .swiper-hero-logo{
            bottom: 20px;
        }
    }

    // section about
    .section-home-about{
        .about-conteiner{
            .about-content{
                h2{
                    font-size: 46px;
                }
            }
        }
    }
    // section why bsw
    .parallax-bsw .parallax-content{
        padding: 140px 0 120px 0px;
    }
    ul.why-bsw-grid .why-bsw-item{
        .hover-content{
            padding: 20px;
        }
        .title{
            padding-left: 20px;
            padding-top: 25px;
        }
    }

    // section-values
    .section-values{
        padding-bottom: 70px;
    }

    // section news
    .section-news{
        .section-title{
            padding: 70px 0;
        }

        .news-swiper-container {
            padding-top: 10px;
            padding-bottom: 40px;
        }

        .swiper-slide-news h2{
            font-size: 42px;
        }
    }

    .section-video {
        padding-bottom: 70px;
    }
}


@include media-breakpoint-down(lg) {
    .page-home{
        padding-top: 0;
    }
    
    // parallax
    .parallax {
        .parallax-image {
            background-attachment: scroll;
        }
    }
    
    // section values
    .section-values{
        padding: 70px 0;
        padding-bottom: 10px;
        h2:after{
            content: none;
        }
        .values-container:before{
            content: none;
        }
        ul.values-grid{
            margin-top: 40px;
            .values-item .values-text{
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }

    // section news
    .section-news{
        .news-swiper-container .swiper-slide-news .content{
            padding: 5%;
        }
        .swiper-slide-news h2 {
            font-size: 34px;
        }
        .news-swiper-container{
            padding-top: 40px;
        }
    }

    // section contact
    .section-contact{
        h2:after{
            content: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .image-pull-7{
        width: 100%;
        max-width: 100%;
    }

    // hero
    .slide-hero {
        .slide-hero-content{
            padding: 60px 40px;
        }
    }

    // section about
    .section-home-about{
        .about-conteiner .about-image{
            flex: 0 0 180px;
        }
    }

    // section why bsw
    .parallax-bsw .parallax-content{
        padding: 70px 0;
    }

    ul.why-bsw-grid{
        li{
            flex: 1 1 50%;
        }
    }


    // section values
    .section-values{
        ul.values-grid{
            .values-item {
                padding: 30px 5px 30px 5px;
            }
        }

        ul.values-grid {
            flex-wrap: wrap;
            justify-content: center;
            > li{
                flex: 0 0 50%;
            }
            > li:nth-child(even):after{
                content: none;
            }
            .values-item{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    // section news
    .section-news{
        ul.news-list{
            li{
                flex: 1 1 33.333%;
            }
            li:nth-child(n+4){
                display: none;
            }
        }

        .news-swiper-container {
            padding-top: 10px;
        }
    }


    // section-contact
    .section-contact{
        padding: 40px 0;
        h5{
            margin-bottom: 25px;
        }
    }

    .section-video {
        padding-bottom: 40px;
    }
}


@include media-breakpoint-down(sm) {

    // swiper hero
    .slide-hero {
        .slide-hero-content{
            padding: 70px 20px;
            [class^="col-"]{
                padding-left: 0;
                padding-right: 0;
            }
            h2{
                font-size: 26px;
                br{
                    display: none;
                }
            }
            h3{
                font-size: 20px;
            }
        }
        
        .slide-hero-image {
            height: 92vh;
        }
    }

    #swiper-hero{
        .swiper-hero-logo{
            img{
                max-width: 35vw;
                
            }
        }
        .swiper-hero-scroll{
            display: none;
        }
    }

    // section about
    .section-home-about{
        padding: 30px 0;
        .about-conteiner{
            display: block;
            .about-image{
                padding: 0 10%;
            }
            .about-content{
                padding: 20px 0;
                h2{
                    font-size: 26px;
                    margin-bottom: 20px;
                }
                h5{
                    font-size: 20px;
                    margin-bottom: 10px;
                }
            }

            .about-signature{
                margin-top: 15px;
            }
        }
    }
    
    .parallax-bsw{
        h2{
            font-size: 32px;
        }
        h3{
            font-size: 26px;
        }
    }

    ul.why-bsw-grid .why-bsw-item{
        .title{
            padding-left: 20px;
            padding-top: 15px;
            font-size: 16px;
        }
        .hover-content h5{
            margin-bottom: 0;
        }
    }

   

    // section values
    .section-values{
        padding: 30px 0;
        h2{
            margin-bottom: 25px;
        }
        ul.values-grid{
            margin: 20px 0;
            flex-wrap: wrap;
            > li{
                flex: 0 0 50%;
                padding: 0;
            }
            
            .values-item{
                padding-bottom: 20px;
            }
            .values-title{
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 8px;
            }
            .values-icon{
                font-size: 54px;
            }

            .values-text{
                padding: 10px 5px;
                font-size: 12px;
            }

            > li:after{
                content: none;
            }

            > li:nth-child(odd):after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                height: 95px;
                width: 1px;
                background-color: #dadada;
            }
            > li:last-child:after{
                content: none;
            }
        }
    }

    // section news
    .section-news{
        .section-title{
            display: block;
            padding: 30px 0;
        }
        ul.news-list{
            > li{
                flex: 1 1 50%;
                padding: 10px;
            }
            > li:nth-child(n+3){
                display: none;
            }

            .news-list-item {
                .news-title{
                    font-size: 14px;
                    font-weight: 500;
                }
                .news-meta{
                    font-size: 11px;
                    font-weight: 500;
                }
            }
        }

        .news-swiper-container{
            padding-top: 20px;
            .swiper-slide-news{
                display: block;
                .image{
                    display: none;
                }
                .content{
                    padding: 25px 0;
                }
                h2{
                    font-size: 28px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    // section contact
    .section-contact{
        padding: 30px 0;
        h2{
            margin-bottom: 15px;
        }
        .contact-data-group + .contact-data-group{
            margin-top: 10px;
        }
        .image-pull-7{
            margin-top: 25px;
        }
    }
}