// 
// Article
// 

article{
    margin-bottom: 120px;
    h1, .h1 {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        margin-bottom: 45px;
    }
    
    h5, .h5{
        color: $secoundary;
    }

    p a{
        text-decoration: underline;
        &:hover, &:focus{
            text-decoration: underline;
        }
    }

    .article-title{
        position: relative;
        padding-bottom: 20px;
        &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -45px;
            width: 100px;
            height: 1px;
            background-color: $secoundary;
        }
    }
    .article-title.no-divider{
        padding-bottom: 0;
        &:after{
            content: none;
        }
    }

    .article-title.divider-bottom {
        margin-top: 20px;
        &:after{
            bottom: 25px;
        }
    }

    .article-content > ul.list-boxed:first-child > li:first-child{
        padding-top: 0;
    }
    

    .article-content{
        padding-left: 18%;
        padding-right: 12%;
        > * {
            margin: 30px 0;
        }

        .tab-content{
            margin: 0;
        }

        .tab-pane > * {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .tab-pane > .boxed-icon-item{
            margin-top: 0;
            margin-bottom: 0;
        }
        

        .tab-pane > ul.list-boxed:first-of-type > li:first-child{
            padding-top: 0;
        }
        
        ul.list-grid-images, ul.list-download, hr{
            margin-left: -20px;
            margin-right: -20px;
        }
        
        ul.list-steps-icons{
            margin: 50px -10% 50px -15px;
        }
        #faq{
            margin-left: -12%;
            margin-right: -17%;
        }
        .article-image{
            margin-top: 50px;
            margin-bottom: 40px;
        }

        .article-image + ul.list-boxed li:first-child{
            padding-top: 0;
        }

        ul.list-menu-2{
            margin-left: -15%;
            margin-right: -17%;
            padding-left: 13%;
            margin-top: 10px;
            margin-bottom: 0px;
        }


        ul.list-menu-2 ~ .article-image{
            padding-left: 10%;
            margin-top: 0;
        }

        blockquote{
            margin: 80px -10%;
            h5{
                display: inline-block;
                padding: 30px 40px;
                text-align: center;
                font-size: 26px;
                position: relative;
                &:before, &:after{
                    font-family: 'icomoon' !important;
                    color: #d4d4d4;
                    font-size: 26px;
                    line-height: 1;
                    position: absolute;
                }
                &:before{
                    content: "\e936";
                    left: 0;
                    top: 0;
                }
                &:after{
                    content: "\e935";
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

    .article-content.no-wrap {
        padding-left: 0;
    }

    ul.list-boxed, .article-image, .person-item, .table-wrapper, .article-gallery, .google-calendar-container, .boxed-item{
        margin-left: -25%;
        margin-right: -17%;
    }

    .boxed-item{
        margin-top: 0;
        margin-bottom: 0;
    }
    
    .article-image{
        margin-left: 0;
        margin-right: 0;
    }
    
    ul.list-boxed-icons{
        margin-left: -20%;
        margin-right: -17%;
    }

    .boxed-icon-item{
        margin-left: -20%;
        margin-right: -17%;
    }
    
    .article-image{
        img{
            display: block;
            width: 100%;
        }
    }

    .article-image-desc{
        display: flex;
        align-items: center;
        margin: 45px 0;
        .image, .desc{
            flex: 1 1 50%;
        }
        .image{
            padding-right: 5%;
        }
        .desc{
            padding-left: 5%;
            p:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    .paragraph-group{
        p{
            margin: 0;
        }
    }

    .news-meta{
        font-family: 'Asap', sans-serif;
    }
}

.content-vertical-images{
    display: flex;
    .cvi-img{
        flex: 0 0 130px;
        padding-right: 20px;
        ul.list-grid-images{
            margin-left: 0;
            margin-right: 0;
            display: block;
            > li{
                padding: 10px 0;
                border-right: 0px;
                border-bottom: 1px solid $gray-200;
            }
            > li:first-child{
                padding-top: 0;
                margin-top: 0;
            }
        }
    }
    .cvi-text{
        
    }
}

.article-values-grid {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    >li {
        flex: 0 0 33.33%;
        text-align: center;
        padding: 10px;
        position: relative;
    }
    
    .values-item {
        display: block;
        padding: 66px 5px 30px 5px;
        background-color: $white;
        transition: all 0.3s;
        overflow: hidden;
    }
    
    .values-title {
        font-size: 20px;
        font-weight: 700;
        color: $primary;
        font-family: 'Asap', sans-serif;
        margin-bottom: 25px;
    }
    .values-icon {
        color: $primary;
        font-size: 90px;
    }
    .values-text{
        padding: 25px 20px;
    }

    .values-item:hover {
        background-color: $gray-100;
    }
}

.blockquote-primary {
    background-color: $primary;
    color: $white;
    padding: 50px 10%;
    font-size: 34px;
    font-family: 'Asap', sans-serif;
    text-align: center;
    font-weight: 700;

    .blockquote-footer {
        margin-top: 20px;
        color: $white;
        font-size: 20px;
        font-family: 'Playfair Display', serif;
        font-size: 16px;
        text-align: right;
    }
}


@include media-breakpoint-down(xl) {
    article{
        h1, .h1 {
            margin-bottom: 30px;
        }
    }
}


@include media-breakpoint-down(md) {
    article{
        margin-bottom: 80px;
        .article-title{
            padding-bottom: 0px;
            &:after{
                content: none;
            }
        }
        .article-content{
            padding-left: 0%;
            padding-right: 0%;
            > * {
                margin: 30px 0;
            }
            
            ul.list-grid-images, ul.list-download, hr{
                margin-left: 0px;
                margin-right: 0px;
            }
            
            ul.list-steps-icons{
                margin: 50px 0% 50px 0px;
            }
            #faq{
                margin-left: 0%;
                margin-right: 0%;
            }

            ul.list-menu-2{
                padding-left: 0;
                margin: 10px 0 0px 0px;
            }

            ul.list-menu-2 ~ .article-image{
                padding-left: 0;
            }

            blockquote{
                margin: 40px 0;
            }
        }
    
        ul.list-boxed, .article-image, .person-item, .table-wrapper, .article-gallery, .google-calendar-container{
            margin-left: 0%;
            margin-right: 0%;
        }

        ul.list-boxed, ul.list-boxed-icons, .boxed-item{
            margin-left: -15px;
            margin-right: -15px;
        }

        .boxed-icon-item{
            margin-left: -15px;
            margin-right: -15px;
        }

        .article-image-desc{
            align-items: flex-start;
            margin: 30px 0;
        }
    }

    .content-vertical-images{
        .cvi-img{
            flex: 0 0 80px;
            padding-right: 20px;
        }
    }

    .article-values-grid {
       >li {
            flex: 0 0 100%;
            padding: 10px;
        }
        
        .values-item {
            padding: 30px 5px 30px 5px;
        }
    }

    .blockquote-primary {
        margin-left: -15px!important;
        margin-right: -15px!important;
        font-size: 22px;
    }
}


@include media-breakpoint-down(sm) {
    article{
        margin-bottom: 20px;
        .article-image-desc{
            display: block;
            .desc {
                padding-left: 0%;
                padding-top: 20px;
            }
        }
        .article-content{
            blockquote{
                margin: 20px 0;
                h5{
                    padding: 20px 24px;
                    font-size: 20px;
                    &:before, &:after{
                       font-size: 20px;
                        
                    }
                }
            }
        }
    }
}
