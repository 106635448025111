//
// header
//
.header {
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    padding: 40px 0;
    background-color: transparent;
    transition: all 0.3s;
    .header-row {
        display: flex;
        align-items: center;
    }
    .header-brand {
        flex: 0 0 380px;
        img{
            max-width: 370px;
            transition: all 0.3s;
        }
    }
    ul.header-menu {
        padding-left: 10%;
        flex: 1 1 auto;
        display: flex;
        margin: 0;
        justify-content: space-between;
        align-items: center;
        >li>a {
            color: $white;
            font-size: 18px;
            font-family: 'Asap', sans-serif;
            display: block;
            padding: 10px 0;
        }
        > li:hover > a{
            color: $secoundary;
        }
    }
    #toggle-menu{
        display: none;
    }
}

.header-sticky{
    background-color: $primary;
    padding: 5px 0px;
    .header-brand {
        img{
            max-width: 200px;
        }
    }
}

.has-drop {
    position: relative;
}

ul.drop-menu {
    font-family: 'Asap', sans-serif;
    background-color: $primary;
    position: absolute;
    left: -45px;
    top: 100%;
    padding: 20px 0;
    padding-bottom: 40px;
    >li {
        padding: 10px 50px;
        padding-right: 90px;
    }
    >li>a {
        color: $white;
        white-space: nowrap;
        transition: all 0.3s;
        &:hover {
            color: $secoundary;
        }
    }
    &:before {
        content: "";
        position: absolute;
        left: 75px;
        top: -8px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid $primary;
    }
}

ul.drop-menu ul.drop-menu {
    background-color: #09103c;
    top: -10px;
    left: 100%;
    left: calc(100% - 45px);
    >li>a {
        color: #6a81ff;
        &:hover {
            color: $secoundary;
        }
    }
    &:before {
        content: "";
        position: absolute;
        left: -8px;
        top: 45px;
        width: 0;
        height: 0;
        width: 0;
        height: 0;
        border: 0px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 8px solid #09103c;
    }
}

ul.drop-menu {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    transform: translateY(30px);
}

.has-drop:hover>ul.drop-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

.header-enquiry-btn-wrapper {
    padding: 0px 10px;
}

.header-enquiry-btn {
    display: flex!important;
    align-items: center;
    background-color: $secoundary;
    padding: 8px 18px!important;
    border-radius: 40px;
    color: $white;

    [class^="icon-"],
    [class*=" icon-"] {
        margin-right: 4px;
        font-size: 22px;
    }

    &:hover {
        background-color: darken($secoundary, 7%);
        color: $white!important;
    }
}


@include media-breakpoint-down(xl) {
    .header{
        .header-brand{
            flex: 0 0 310px;
            img{
                max-width: 300px;
            }
        }
        ul.header-menu{
            padding-left: 4%;
        }

        ul.header-menu {
            >li>a {
                font-size: 16px;
            }
        }
    }

    .header-sticky{
        background-color: $primary;
        padding: 5px 0px;
        .header-brand {
            img{
                max-width: 200px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .header, .header-sticky{
        .header-brand{
            flex: 0 0 160px;
            padding: 5px 0;
            img{
                max-width: 140px;
            }
        }
    }
    .header{
        padding: 0;
        background-color: $primary;
        .header-row{
            justify-content: space-between;
        }
        ul.header-menu{
            display: none;
        }

        #toggle-menu{
            display: block;
        }
    }
}