body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Playfair Display', serif;
    color: $text-color;
    font-size: 15px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: 'Asap', sans-serif;
    font-weight: 700;
    color: $primary;
}

h1, .h1 {
    font-size: 80px;
}

h2, .h2 {
    font-size: 68px;
}

h3, .h3 {
    font-size: 60px;
}

h4, .h4 {
    font-size: 26px;
}

h5, .h5 {
    font-size: 22px;
}

h6, .h6 {
    font-size: 20px;
}

.type-asap{
    font-family: 'Asap', sans-serif;
}
.type-bold{
    font-weight: 700;
}


a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
    text-decoration: none;
    outline: none;
}

a {
    color: $primary;
    transition: all 0.35s;
}

a:hover,
a:focus {
    color: $secoundary;
    
}

@include media-breakpoint-down(xl) {
    h1, .h1 {
        font-size: 64px;
    }
    
    h2, .h2 {
        font-size: 52px;
    }
    
    h3, .h3 {
        font-size: 44px;
    }
}

@include media-breakpoint-down(lg) {
    h1, .h1 {
        font-size: 50px;
    }
}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(sm) {
    h1, .h1 {
        font-size: 26px;
    }
    
    h2, .h2 {
        font-size: 26px;
    }
    
    h3, .h3 {
        font-size: 26px;
    }
    h5, .h5{
        font-size: 20px;
    }
}