// 
// Aos
// 
[data-aos="offer-animation"] {
    .offer-item{
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.4s 0.35s;
    }
    &.aos-animate{
        .offer-item{
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

[data-aos="history-text"] {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.4s 0.4s;
    &.aos-animate{
        opacity: 1;
        transform: translateY(0px);
    }
}

[data-aos="history-timeline"] {
    @media screen and (min-width: 992px) {
        .history-timeline{
            &:after{
                height: 0;
                transition: all 1s 0.25s;
            }

            .timeline-item{
                .timeline-image:after{
                    width: 0%;
                    transition: all 0.3s 1s;
                }
            }
            .timeline-left{
                .timeline-image img, .timeline-content{
                    display: block;
                    transform: translateX(50px);
                    opacity: 0;
                    transition: all 0.4s 1.25s;
                }
            }

            .timeline-right{
                .timeline-image img, .timeline-content{
                    display: block;
                    transform: translateX(-50px);
                    opacity: 0;
                    transition: all 0.4s 1.25s;
                }
            }
        }
        
        
        &.aos-animate {
            .history-timeline{
                &:after{
                    height: 100%;
                }

                .timeline-item{
                    .timeline-image:after{
                        width: 15%;
                    }
                }
                .timeline-left{
                    .timeline-image img, .timeline-content{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                }

                .timeline-right{
                    .timeline-image img, .timeline-content{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                }
            }
        }
    }
}