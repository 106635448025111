// 
// Offcanvas
// 
.offcanvas {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 53px;
    bottom: 0px;
    background-color: $primary;
    z-index: 999;
    transform: translate3d(-100%, 0, 0);
    transition: all .4s;
    padding: 40px 0px;
    overflow-y: auto;

    ul.offcanvas-menu{
        > li > a{
            color: $white;
            display: block;
            padding: 10px 20px;
            border-bottom: 1px solid rgba( $white, 0.2 );
        }
    }
    ul.drop-menu{
        position: static;
        display: none;
        opacity: 1;
        visibility: visible;
        transform: none;
        padding: 20px 5px;
        background-color: transparent;
        background-color: #121b4f;
    }

    ul.drop-menu ul.drop-menu{
        background-color: #000!important;
    }

    ul.drop-menu:before{
        content: none;
    }

    ul.drop-menu.drop-show{
        display: block;
    }

    ul.drop-menu > li {
        padding: 0px;
    }
    ul.drop-menu > li > a{
        padding: 8px 0px;
        display: block;
        padding-left: 15px;
        color: rgba( $white, 0.8);
    }
    ul.drop-menu ul.drop-menu{
        background-color: transparent;
        padding: 10px 0;
        padding-left: 20px;
    }

    ul.drop-menu ul.drop-menu:before{
       content: none;
    }
}

body.menu-is-active{
    overflow-y: hidden;
    #offcanvas {
        transform: translate3d(0, 0, 0);
    }
}