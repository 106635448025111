// 
// Cards
// 
.card {
    border-radius: 0px;
    background-color: transparent;
    border: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
    transition: all 0.35s;
    position: relative;
    margin-bottom: 30px;
    transition: all 0.3s;
    .card-body {
        padding: 20px;
    }
    .card-date, .card-title{
        transition: all 0.3s;
    }
    .card-meta {
        font-size: 14px;
        font-family: 'Asap', sans-serif;
    }
}
.card:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    .card-date, .card-title{
        color: $primary;
    }
}



@include media-breakpoint-down(md) {
    .card{
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
}