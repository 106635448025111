// 
// faq
// 

#faq{
    [data-toggle="collapse"] {
        font-size: 20px;
        color: $secoundary;
        font-weight: 700;
        display: block;
        position: relative;
        padding: 14px 0px 14px 9.5%;
        //border-bottom: 1px solid transparent;
        font-family: 'Asap', sans-serif;
        background-color: $gray-100;
        &:before {
            font-family: 'icomoon' !important;
            font-size: 10px;
            position: absolute;
            left: 7%;
            top: 22px;
            color: $secoundary;
            content: "\ea0b";
        }
    }
    [data-toggle="collapse"].collapsed {
        color: $primary;
        //border-bottom: 1px solid $gray-300;
        background-color: $white;
        &:before {
            color: $primary;
            content: "\ea0a";
        }
        &:hover {
            color: $primary;
        }

        &:after{
            content: "";
            position: absolute;
            left: 9.5%;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: $gray-300;
        }
    }

    .collapse-inner{
        background-color: $gray-100;
        padding: 0 9.5%;
        padding-bottom: 20px;
        
        p:last-of-type{
            margin-bottom: 0;
        }
    }
}


@include media-breakpoint-down(md) {
    #faq [data-toggle="collapse"]{
        padding: 14px 0px 14px 20px;
        &:before{
            left: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    #faq [data-toggle="collapse"]{
        font-size: 16px;
        &:before{
            font-size: 8px;
            top: 20px;
        }
    }
}
