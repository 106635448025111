// 
// Badge
// 
.badge{
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 12px 25px;
}