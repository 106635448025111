// 
// Table
// 
.table-wrapper {
    border: 1px solid $gray-300;
    padding: 30px 22%;
}

table {
    width: 100%;
    thead th,
    tbody td {
        padding: 10px 15px;
        font-weight: 400;
        vertical-align: top;
    }
    thead {
        border-bottom: 1px solid $gray-300;
    }
}

// table small
.table-small {
    thead th {
        font-size: 15px;
    }
    tbody td,
    tbody td p {
        font-size: 13px;
    }
}

// table center
.table-center {
    tr>td,
    tr>th {
        text-align: center;
    }
    tr>td:first-child,
    tr>th:first-child {
        text-align: left;
    }
}

// table-border
.table-border {
    tbody td {
        border-top: 1px solid $gray-300;
        padding: 30px 15px;
    }
    tbody td p+p {
        margin-top: 50px;
    }
    tbody tr:first-child>td {
        border: 0px;
    }
}

// table date
.table-date {
    tbody td:first-child {
        white-space: nowrap;
        padding-right: 40px;
    }
}

.table-font-asap{
    
}

// table-school-day
.table-school-day{
    tbody td p{
        font-weight: 700;
        font-family: 'Asap', sans-serif;
    }
    tbody td:first-child p {
        font-weight: 400;
        font-family: 'Playfair Display', serif;
    }
}


@include media-breakpoint-down(md) {
    .table-wrapper {
        padding: 30px 15px;
    }
}


@include media-breakpoint-down(sm) {
    .table-wrapper{
        padding: 10px;

        table tbody td, table thead th{
            padding: 10px;
            font-size: 14px;
        }
    }
    .table-date{
        display: block;
        thead, tbody, td, tr, th{
            display: block;
        }
        thead{
            display: none;
        }
        tbody td{
            padding: 10px 5px;
            border:0px;
        }
        tbody td:first-child{
            padding-bottom: 0;
        }
        tbody td:first-child p{
            font-weight: 700;
        }
        p{
            margin-bottom: 0px;
        }
        tbody tr{
            border-bottom: 1px solid $gray-300;
        }

        tbody tr:last-child{
            border:0px;
        }
    }

    .table-border tbody td p + p {
        margin-top: 0px;
    }
    
}