// colors
$dark:             #000;
$white:            #fff;
$text-color:       #2b2d36;
$primary:          #182466;
$secoundary:       #db861d;
$danger:           #b30000;

$gray-100:         #f8faff;
$gray-200:         #e5e5e5;
$gray-300:         #dadada;
$gray-400:         #f1f2f8;







