//
// FORM CONTROL
//
.form-control {
    border-radius: 0px;
    font-size: 14px;
    padding: 18px 35px;
    height: 75px;
    font-weight: 300;
    color: $text-color;
    background-color: transparent;
    border: 1px solid $gray-300;
    color: $dark;
}

.form-control:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: $primary;
}

textarea.form-control {
    height: auto;
    line-height: 1.3;
}

label{
    font-family: 'Asap', sans-serif;
    font-size: 15px;
    margin-bottom: 2px;
}


// 
// Bs datapicker
// 
.datepicker table tr td.active, .datepicker table tr td.active:hover, 
.datepicker table tr td.active.disabled, 
.datepicker table tr td.active.disabled:hover{
    background-image: none;
    background-color: $secoundary!important;
}

//
// FORM GROUP
//
.form-group {
    position: relative;
    margin-bottom: 25px;
}

.form-group-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        flex: 0 0 auto;
        margin-left: 20px;
    }
}

//
// PLACEHOLDERS
//
input ::-webkit-input-placeholder {
    color: $text-color;
}

input ::-moz-placeholder {
    color: $text-color;
}

input :-ms-input-placeholder {
    color: $text-color;
}

input :-moz-placeholder {
    color: $text-color;
}


//
// CUSTOM RADIO, CUSTOM CHECKBOXES
//
.custom-control{
    margin: 0px 0;
    padding-left: 45px;
    min-height: 30px;
    padding-top: 6px;
    a{
        color: $primary;
        text-decoration: underline;
        font-size: 13px;
        transition: all 0.3s;
        &:hover{
            color: $secoundary;
        }
    }
}
.custom-control-label{
    font-size: 13px;
}

.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
    width: 30px;
    height: 30px;
    border-radius: 0px;
}

.custom-control-label::before{
    background-color: transparent;
    border:1px solid $dark;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    background-color: $primary;
    border:1px solid $primary;
}

.custom-control-input:focus ~ .custom-control-label::before{
    box-shadow: none;
}


// 
// Nice select
// 
.nice-select {
    font-size: 15px;
    color: $text-color;
    width: 100%;
    z-index: 88;
    border-color: $gray-400;
    border-radius: 0px;
    position: relative;
    z-index: 89;
    height: 75px;
    line-height: 75px;
    float: none;
    display: block;

    &:before{
        content: "";
        position: absolute;
        right: 2px;
        top: 2px;
        bottom: 2px;
        width: 30px;
        background-color: $gray-100;
    }
    &:after{
        border:0px;
        content: "\e920";
        font-family: 'icomoon' !important;
        font-size: 8px;
        color: $primary;
        transform: none;
        width: auto;
        height: auto;
        line-height: 1;
    }
    .list {
        font-size: 13px;
        left: 0 !important;
        right: 0 !important;
        z-index: 999999;
        border-radius: 0px;
    }
    .option{
        min-height: 50px;
        line-height: 50px;
        color: $primary;
    }
}

.nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: $primary;
    z-index: 9999;
    &:after{
        content: "\e923";
        transform: none;
    }
}

.nice-select.select-small{
    display: inline-block;
    border:0px;
    font-size: 15px;
    font-weight: 400;
    text-align: right!important;
    height: 40px;
    line-height: 40px;
    width: auto;
    min-width: 220px;
    &:before{
        content: none;
    }
}

.nice-select.select-up{
    .list {
        top: auto;
        bottom: 100%;
    }
}

.select-fixed{
    .list{
        max-height: 250px;
        overflow-y: auto;
    }
}

//
// VALIDATION
//
.parslay-danger {
    display: none;
}

.form-control.form-control-danger {
    border-color: $danger;
}

.form-group.has-danger {
    label:before {
        border-color: $danger;
    }
}
.required-label{
    color: $danger;
}

select.form-control-danger ~ .nice-select{
    border-color: $danger;
}




//
// [data-filter]
// 
.data-filter-group div[data-filter] {
    display: none;
}

.data-filter-group div[data-filter].active {
    display: block;
}


// 
// section-form
// 
.section-form{
    margin-top: 40px;
    h5{
        margin-bottom: 45px;
    }
    .form-info{
        padding: 10px 20px;
        p{
            font-size: 13px;
        }
    }

    .form-row{
        position: relative;
    }
}
[data-copy], [data-remove]{
    position: absolute;
    top: 50px;
    font-size: 20px;
}

[data-copy]{
    right: -20px;
    top: 54px;
    font-size: 14px;
}
[data-remove]{
    right: -50px;
    display: none;
}
.cloned [data-remove]{
    display: block;
}

form{
    .section-form:first-of-type{
        margin-top: 0;
    }
}

.embeded-form {
    margin-top: 50px;
    min-height: 580px;
    padding: 20px 12px 20px 20px;
    margin-bottom: 30px;
    background-color: $primary;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, label {
        color: $white;
    }

    label {
        font-size: 13px;
        font-weight: 700;
        font-family: 'Asap', sans-serif;
    }

    .hs-form-field {
        margin-bottom: 10px;
    }

    .hs-input {
        min-height: 48px;
        padding-left: 10px;
    }

    textarea, select, input {
        width: 100%!important;
    }

    textarea {
        min-height: 160px!important;
    }

    input[type="submit"] {
        font-family: 'Asap', sans-serif;
        display: block;
        width: 100%;
        background-color: $secoundary;
        color: $white;
        border: 0;
        padding: 14px 18px;
        border-radius: 40px;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
    }
    
    .form-title {
        text-align: center;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $secoundary;
        width: calc(100% - 8px);
    }
}

@include media-breakpoint-down(md) {
    .form-group-flex {
        display: block;
    }

    .form-row-icons{
        padding-right: 60px;
    }

    [data-copy]{
        right: 40px;
        
    }
    [data-remove]{
        right: 10px;
    }

    .embeded-form {
        margin-top: 0;
        margin-left: -15px;
        margin-right: -15px;
    }
}


@include media-breakpoint-down(sm) { 
    
    
}