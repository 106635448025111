@font-face {
    font-family: 'icomoon';
    src: url(../fonts/icomoon.eot?1cobi);
    src: url(../fonts/icomoon.eot?1cobi#iefix) format('embedded-opentype'), url(../fonts/icomoon.ttf?1cobi) format('truetype'), url(../fonts/icomoon.woff?1cobi) format('woff'), url(../fonts/icomoon.svg?1cobi#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-respect:before {
    content: "\e937";
}

.icon-qoute-end:before {
    content: "\e935";
}

.icon-qoute-start:before {
    content: "\e936";
}

.icon-arrow-lg-down:before {
    content: "\e931";
}

.icon-arrow-lg-left:before {
    content: "\e932";
}

.icon-arrow-lg-right:before {
    content: "\e933";
}

.icon-arrow-lg-up:before {
    content: "\e934";
}

.icon-arrow-bottom-sm:before {
    content: "\e900";
}

.icon-arrow-down-sm:before {
    content: "\e901";
}

.icon-arrow-down:before {
    content: "\e902";
}

.icon-arrow-left-sm:before {
    content: "\e903";
}

.icon-arrow-left:before {
    content: "\e904";
}

.icon-arrow-right-sm:before {
    content: "\e905";
}

.icon-arrow-right:before {
    content: "\e906";
}

.icon-arrow-up-sm:before {
    content: "\e907";
}

.icon-arrow-up:before {
    content: "\e908";
}

.icon-calendar:before {
    content: "\e909";
}

.icon-clock:before {
    content: "\e90a";
}

.icon-close:before {
    content: "\e90b";
}

.icon-cooperate:before {
    content: "\e90c";
}

.icon-crop:before {
    content: "\e90f";
}

.icon-cross-out:before {
    content: "\e910";
}

.icon-daycare-center:before {
    content: "\e911";
}

.icon-dependable:before {
    content: "\e912";
}

.icon-down-arrow:before {
    content: "\e913";
}

.icon-download-s:before {
    content: "\e914";
}

.icon-download:before {
    content: "\e915";
}

.icon-envelope:before {
    content: "\e916";
}

.icon-exam:before {
    content: "\e917";
}

.icon-eye:before {
    content: "\e918";
}

.icon-facebook:before {
    content: "\e919";
}

.icon-garbage:before {
    content: "\e91a";
}

.icon-magnifying-glass:before {
    content: "\e91b";
}

.icon-olive:before {
    content: "\e91c";
}

.icon-people:before {
    content: "\e91d";
}

.icon-pin:before {
    content: "\e91e";
}

.icon-plus1:before {
    content: "\e930";
}

.icon-search:before {
    content: "\e91f";
}

.icon-tr-down:before {
    content: "\e920";
}

.icon-tr-left:before {
    content: "\e921";
}

.icon-tr-right:before {
    content: "\e922";
}

.icon-tr-up:before {
    content: "\e923";
}

.icon-youtube-logo:before {
    content: "\e92e";
}

.icon-youtube:before {
    content: "\e92f";
}

.icon-image:before {
    content: "\e90d";
}

.icon-images:before {
    content: "\e90e";
}

.icon-file-empty:before {
    content: "\e924";
}

.icon-files-empty:before {
    content: "\e925";
}

.icon-file-text2:before {
    content: "\e926";
}

.icon-file-picture:before {
    content: "\e927";
}

.icon-file-music:before {
    content: "\e928";
}

.icon-file-play:before {
    content: "\e929";
}

.icon-file-video:before {
    content: "\e92a";
}

.icon-file-zip:before {
    content: "\e92b";
}

.icon-copy:before {
    content: "\e92c";
}

.icon-paste:before {
    content: "\e92d";
}

.icon-location2:before {
    content: "\e948";
}

.icon-compass:before {
    content: "\e949";
}

.icon-history:before {
    content: "\e94d";
}

.icon-clock1:before {
    content: "\e94e";
}

.icon-clock2:before {
    content: "\e94f";
}

.icon-alarm:before {
    content: "\e950";
}

.icon-stopwatch:before {
    content: "\e952";
}

.icon-search1:before {
    content: "\e986";
}

.icon-zoom-in:before {
    content: "\e987";
}

.icon-zoom-out:before {
    content: "\e988";
}

.icon-enlarge:before {
    content: "\e989";
}

.icon-shrink:before {
    content: "\e98a";
}

.icon-enlarge2:before {
    content: "\e98b";
}

.icon-shrink2:before {
    content: "\e98c";
}

.icon-download2:before {
    content: "\e9c5";
}

.icon-link:before {
    content: "\e9cb";
}

.icon-notification:before {
    content: "\ea08";
}

.icon-plus:before {
    content: "\ea0a";
}

.icon-minus:before {
    content: "\ea0b";
}

.icon-cross:before {
    content: "\ea0f";
}

.icon-checkmark:before {
    content: "\ea10";
}

.icon-checkmark2:before {
    content: "\ea11";
}

.icon-arrow-up-left2:before {
    content: "\ea39";
}

.icon-arrow-up2:before {
    content: "\ea3a";
}

.icon-arrow-up-right2:before {
    content: "\ea3b";
}

.icon-arrow-right2:before {
    content: "\ea3c";
}

.icon-arrow-down-right2:before {
    content: "\ea3d";
}

.icon-arrow-down2:before {
    content: "\ea3e";
}

.icon-arrow-down-left2:before {
    content: "\ea3f";
}

.icon-arrow-left2:before {
    content: "\ea40";
}

.icon-facebook1:before {
    content: "\ea90";
}

.icon-facebook2:before {
    content: "\ea91";
}

.icon-instagram:before {
    content: "\ea92";
}

.icon-telegram:before {
    content: "\ea95";
}

.icon-twitter:before {
    content: "\ea96";
}

.icon-rss:before {
    content: "\ea9b";
}

.icon-rss2:before {
    content: "\ea9c";
}

.icon-youtube1:before {
    content: "\ea9d";
}

.icon-youtube2:before {
    content: "\ea9e";
}

.icon-twitch:before {
    content: "\ea9f";
}

.icon-vimeo:before {
    content: "\eaa0";
}

.icon-vimeo2:before {
    content: "\eaa1";
}

.icon-flickr:before {
    content: "\eaa3";
}

.icon-flickr2:before {
    content: "\eaa4";
}

.icon-tumblr:before {
    content: "\eab9";
}

.icon-tumblr2:before {
    content: "\eaba";
}

.icon-linkedin:before {
    content: "\eac9";
}

.icon-linkedin2:before {
    content: "\eaca";
}

.icon-pinterest:before {
    content: "\ead1";
}

.icon-pinterest2:before {
    content: "\ead2";
}

.icon-file-pdf:before {
    content: "\eadf";
}

.icon-file-openoffice:before {
    content: "\eae0";
}

.icon-file-word:before {
    content: "\eae1";
}

.icon-file-excel:before {
    content: "\eae2";
}

.icon-libreoffice:before {
    content: "\eae3";
}