.enquiry {
    position: fixed;
    z-index: 66;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &-btn {
        width: 85px;
        height: 240px;
        display: block;
        position: relative;
        margin: 10px 0;
        background-color: $primary;
        &:hover{
            background-color: $secoundary;
        }

        > [class^="icon-"] {
            color: $white;
            font-size: 44px;
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            text-align: center;
        }
    }


    .text{
        display: inline-block;
        transform: rotate(-90deg) translateY(-50%);
        position: absolute;
        left: 50%;
        transform-origin: top left;
        bottom: 10px;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        font-family: 'Asap', sans-serif;
        white-space: nowrap;
    }
}

.enquiry-virtual-work {
    transition: all 0.3s;

    ul {
        background-color: $primary;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 280px;
        height: 100%;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-left: 1px solid $white;

        li {
            display: block;
            width: 100%;
        }

        li:last-child a {
            border: 0px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            font-family: 'Asap', sans-serif;
            white-space: nowrap;
            padding: 28px 20px;
            border-bottom: 1px solid $white;
            text-align: center;
            transition: all 0.25s;

            &:hover {
                color: $secoundary;
            }

            > [class^="icon-"] {
                margin-right: 8px;
                font-size: 22px;
            }
        }
    }

    &:hover {
        background-color: $primary;
        transform: translateX(-280px);
    }
}


@include media-breakpoint-down(xl) {
    .enquiry{
        &-btn{
            width: 65px;
            height: 200px;

            > [class^="icon-"]{
                font-size: 38px;
                top: 15px;
            }
        }

        .text{
            bottom: 0px;
        }
    }

    .enquiry-virtual-work {
        ul a {
            padding: 22px 20px;
        }
    }
}


@include media-breakpoint-down(md) {
    .enquiry{
        display: none;
    }
}

