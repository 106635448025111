// 
// Tabs
// 
.nav-tabs {
    border-bottom: 1px solid $gray-400;
}

.nav-tabs .nav-item {
    margin: 0;
}

.nav-tabs .nav-link {
    position: relative;
    border-radius: 0px;
    border: 0px;
    display: block;
    color: $white;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    padding: 20px 30px;
    background-color: transparent;
    transition: all 0.3s;
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 30px;
        bottom: 0px;
        width: 1px;
        background-color: $gray-400;
    }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    opacity: 1;
    background-color: transparent;
    color: $primary;
}

// tab content
.tab-content {
    padding: 15px 0;
    .text-center img {
        margin: 30px 0;
    }
}

// 
// Menu tabs
// 
.menu-nav-tabs {
    ul.nav-tabs {
        margin-bottom: 60px;
    }
}

.menu-tab-content {
    .collapse-inner {
        border: 1px solid $primary;
    }
    [data-toggle="collapse"] {
        font-size: 18px;
        text-transform: uppercase;
        color: $primary;
        font-weight: 700;
        display: block;
        position: relative;
        padding: 14px 0px 14px 60px;
        border-bottom: 1px solid transparent;
        &:before {
            font-family: 'icomoon' !important;
            font-size: 10px;
            position: absolute;
            left: 25px;
            top: 18px;
            color: $primary;
            content: "\ea0b";
        }
    }
    [data-toggle="collapse"].collapsed {
        color: $white;
        border-bottom: 1px solid $gray-400;
        &:before {
            color: $white;
            content: "\ea0a";
        }
        &:hover {
            color: $primary;
        }
    }
    ul.menu-list {
        padding: 30px 0;
        li {
            padding: 15px 60px;
        }
    }
    .menu-list-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .list-item-description {
            flex: 1 1 auto;
            padding-right: 25px;
            h6 {
                margin: 0;
                font-weight: 700;
                font-size: 16px;
                color: $white;
                margin-bottom: 4px;
            }
            p {
                color: $gray-300;
                font-size: 14px;
            }
        }
        .list-item-price {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            line-height: 1;
            color: $white;
            .price {
                margin-left: 30px;
            }
            .weight {
                font-size: 16px;
                font-weight: 400;
                color: $gray-300;
            }
        }
    }
}

.menu-list-badge {
    padding-left: 45px;
}

ul.nav-tabs-bear{
    border-bottom: 0px;
    margin: 25px 0;

    .nav-item:first-child .nav-link{
        padding-left: 0;
    }

    .nav-item:last-child .nav-link:after{
        content: none;
    }

    .nav-link{
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
        padding: 20px 50px;
        span{
            display: block;
            margin-bottom: 15px;
        }
        [class^="icon"]{
            font-size: 48px;
        }

        &:after{
            top: 0;
        }
    }
}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(sm) {
    .nav-tabs .nav-link {
        font-size: 16px;
        padding: 12px 20px;
        background-color: transparent;
        transition: all 0.3s;
        &:after {
            top: 20px;
        }
    }
    .menu-nav-tabs ul.nav-tabs {
        margin-bottom: 20px;
    }
    .menu-tab-content ul.menu-list li {
        padding: 10px;
    }
    .menu-tab-content .menu-list-item .list-item-price {
        .weight {
            font-size: 14px;
        }
        .price {
            margin-left: 10px;
            font-size: 16px;
        }
    }
    .menu-list-badge {
        padding-left: 10px;
    }
    .menu-tab-content ul.menu-list {
        padding: 15px 0;
    }


    ul.nav-tabs-bear{
       margin: 10px 0;
    
        .nav-item:first-child .nav-link{
            padding-left: 0;
        }
    
        .nav-item:last-child .nav-link:after{
            content: none;
        }

        .nav-item{
            flex: 1 1 33.333%;
        }
    
        .nav-link{
            text-transform: uppercase;
            font-size: 11px;
            text-align: center;
            padding: 10px 8px;
            span{
                display: block;
                margin-bottom: 10px;
                min-height: 32px;
            }
            [class^="icon"]{
                font-size: 32px;
            }
        }
    }
}