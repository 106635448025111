.page {
    padding-top: 32.5%;
    &-hero{
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
    }
    &-bredcrumbs{
        padding: 45px 0;
    }

    &-content{
        position: relative;
        background-color: $white;
        z-index: 2;
    }

    &-content-container{
        display: flex;
        .page-main{
            flex: 1 1 auto;
            width: calc(100% - 220px);
        }
        .page-sidebar{
            flex: 0 0 220px;
            width: 220px;
        }

        .page-sidebar-form {
            flex: 0 0 420px;
            width: 420px;
        }
    }
}

.page-with-hero-description{
    padding-top: 42.5%;
    .page-hero {
        padding-bottom: 42.5%;
    }
}

@include media-breakpoint-down(xl) {
    .page{
        &-bredcrumbs{
            padding: 35px 0;
        }
    }

    .page-with-hero-description{
        padding-top: 52.5%;
        .page-hero {
            padding-bottom: 52.5%;
        }
    }
}


@include media-breakpoint-down(lg) {
    .page{
        padding-top: 0;
        &-hero{
            position: relative;
        }
    }

    .page-with-hero-description{
        padding-top: 0;
    }
}


@include media-breakpoint-down(md) {
    .page{
        &-content-container{
            flex-direction: column;
            .page-main{
                width: 100%;
                order: 2;
            }
            .page-sidebar{
                width: 100%;
                order: 3;
            }

            .page-sidebar-form {
                width: 100%;
                order: 1;
            }
        }
        &-bredcrumbs{
            display: none;
        }
    }
}


@include media-breakpoint-down(sm) {
    .page-with-hero-description{
        .page-hero {
            padding-bottom: 120%;
        }
    }
}