// 
// Modal
// 

.modal-content, .modal-footer, .modal-content{
    border-radius: 0px;
}

.modal-expand {
    .modal-content {
        background-color: transparent;
        border:0px;
    }
    .close {
        text-shadow: none;
        opacity: 1;
        font-size: 18px;
        text-align: right;
        padding-bottom: 8px;
        color: $white;
    }
}

.expand-banner-mobile {
    display: block;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.expand-banner-desktop {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.modal-xl {
    @include media-breakpoint-up(lg) {
        max-width: 800px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1140px;
    }
}