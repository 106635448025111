// 
// person
// 
.person-item{
    position: relative;
    display: flex;
    padding: 60px 0;
    margin-bottom: 60px;
    align-items: center;
    &:before{
        content: "";
        position: absolute;
        z-index: -1;
        left: 10%;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: $gray-100;
    }
    .person-image{
        flex: 0 0 30%;
    }
    .person-content{
        flex: 1 1 auto;
        padding: 30px 80px;
        padding-right: 100px;
    }

    .person-title{
        h5, h6{
            margin: 0;
        }
        h5{
            color: $primary;
        }
        h6{
            font-size: 16px;
            font-weight: 400;
            color: $secoundary;
        }
        margin-bottom: 40px;
    }
    P{
        margin-bottom: 25px;
    }
    p:last-of-type{
        margin-bottom: 0;
    }
    .signature{
        padding-top: 25px;
    }

    .person-signature{
        text-align: right;
        margin-top: 40px;
        .person-signature-container{
            display: inline-block;
            text-align: left;
            img{
                margin-top: 10px;
            }
        }
    }
}

.person-item-secoundary{
    align-items: flex-start;
    .person-title{
        h5{
            color: $secoundary;
        }
    }
}


@include media-breakpoint-down(lg) {
    .person-item{
        align-items: flex-start;
        padding: 30px 0;
        .person-content{
            padding: 20px 40px;
            padding-top: 0;
        }
    }
}


@include media-breakpoint-down(sm) {
    .person-item{
        display: block;
        padding: 10px;
        &:before{
            left: 0;
        }
        .person-title{
            margin: 0;
            margin-bottom: 15px;
        }
        .person-content{
            padding: 20px 0px;
        }
    }
}

