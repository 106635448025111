// 
// List
// 
ul,
ol {
    @extend .list-unstyled;
}

// list base
ul.list-base {
    font-size: 15px;
    li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;
        &:before {
            content: "-";
            position: absolute;
            left: 0;
        }
    }
}

// list bullet
ul.list-bullet {
    font-size: 14px;
    li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 20px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 8px;
            height: 8px;
            background-color: #313131;
            border-radius: 50%;
        }
    }
    li:last-child{
        margin-bottom: 0;
    }
}

//
// Lists decimal
//
ul.list-decimal{
    counter-reset: counter-decimal;
    font-size: 16px;
    > li{
        position: relative;
        padding-left: 35px;
        margin-bottom: 10px;
        &:before{
            position: absolute;
            left: 0;
            top: -1px;
            content: counter(counter-decimal, decimal)  ".";
            counter-increment: counter-decimal;
            font-weight: 600;
            color: $gray-300;
        }
    }
}

//
// list download
//
ul.list-download{
    li{
        margin-bottom: 0px;
    }
}

.download-item{
    border-top: 1px solid $gray-300;
    font-size: 13px;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .download-text, .download-link{
        font-weight: 400;
        font-size: 13px;
        transition: all 0.3s;
    }
    
    .download-link{
        display: flex;
        padding: 6px 0;
        align-items: center;
        font-size: 14px;
        font-family: 'Asap', sans-serif;
        position: relative;
        &:before, &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $gray-300;
            height: 1px;
        }
        &:before{
            width: 100%;
        }

        &:after{
            width: 30%;
            transition: all 0.3s;
            background-color: $secoundary;
        }
    }

    .download-link:hover{
        &:after{
            width: 100%;
        }
    }
    
    .download-item:hover{
        .download-text, .download-link{
            color: $primary;
        }
    }
}

//
// list boxed
//
ul.list-boxed{
    li{
        padding: 40px 17%;
    }
    h5{
        margin-bottom: 20px;
    }
    li:nth-child(even){
        background-color: $gray-100;
    }

    p:last-of-type{
        margin-bottom: 0;
    }
}

// 
// boxed item
// 
.boxed-item{
    padding: 40px 17%;
    padding-left: 24%;
    h5{
        margin-bottom: 20px;
    }
}
.bg-gray{
    background-color: $gray-100;
}


//
// list boxed icons
//
ul.list-boxed-icons{
    li{
        padding: 60px 13%;
    }
    h6{
        margin-bottom: 25px;
    }
    li:nth-child(odd){
        background-color: $gray-100;
    }

    p:last-of-type{
        margin-bottom: 0;
    }

    .list-boxed-item{
        display: flex;
        .boxed-icon{
            color: $primary;
            font-size: 92px;
            flex: 0 0 140px;
            line-height: 1;
        }
        .boxed-content{
            flex: 1 1 auto;
        }
    }
}

// 
// boxed item
// 
.boxed-icon-item{
    padding: 60px 18%;
    h6{
        margin-bottom: 25px;
    }
    li:nth-child(odd){
        background-color: $gray-100;
    }

    p:last-of-type{
        margin-bottom: 0;
    }

    .list-boxed-item{
        display: flex;
        
        .boxed-icon{
            color: $primary;
            font-size: 92px;
            flex: 0 0 140px;
            line-height: 1;

            img{
                max-width: 92px;
            }
        }
        .boxed-icon-img{
            font-size: inherit;
        }
        .boxed-content{
            flex: 1 1 auto;
        }
    }
}



// 
// List menu
// 

ul.list-menu{
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    border:0px;
    > li{
        padding: 10px 10px;
    }
    > li:first-child{
        padding-left: 0;
    }
    a{
        font-family: 'Asap', sans-serif;
        font-size: 14px;
    }
}

// 
// List menu 2
// 
ul.list-menu-2{
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    background-color: $gray-100;
    > li{
        padding: 18px 20px;
    }
    a{
        display: block;
        padding: 8px 0;
        font-family: 'Asap', sans-serif;
        font-size: 14px;
        position: relative;
    }
    a.active{
        color: $secoundary;
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: $secoundary;
        }
    }
}

// 
// list-grid-images
// 
ul.list-grid-images{
    display: flex;
    flex-wrap: wrap;
    li{
        flex: 1 1 25%;
        padding: 15px 20px;
        position: relative;
        border-right: 1px solid $gray-300;
        margin: 15px 0;
    }
    li:last-child{
        border:0px;
    }
}

ul.list-grid-images-small {
    display: flex;
    flex-wrap: wrap;
    li {
        flex: 1 1 50%;
        padding: 15px 10px;
        position: relative;
        margin: 15px 0;
    }
}

// 
// list-steps-icons
// 

ul.list-steps-icons{
    margin: 50px 0;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    li{
        flex: 0 0 20%;
        padding: 15px;
    }

    li:last-child{
        .step-icon:after{
            content: none;
        }
    }
}

.step-icon{
    border-radius: 50%;
    position: relative;
    background-color: $primary;
    padding-bottom: 100%;
    &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: translate(75%, -50%);
        border-left: 15px solid $primary;
    }
    .step-num{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -40%);
        width: 37px;
        height: 37px;
        background-color: $secoundary;
        border-radius: 50%;
        span{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $white;
            font-family: 'Asap', sans-serif;
            font-size: 16px;
            font-weight: 700;
        }
    }
    .step-text{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-family: 'Asap', sans-serif;
        font-size: 16px;
        font-weight: 700;
    }
}

// 
// list-steps-text
// 
ul.list-steps-text{
    li{
        margin-bottom: 45px;
    }
    h5{
        margin-bottom: 12px;
    }
    a{
        text-decoration: underline;
    }
}


@include media-breakpoint-down(xl) {
    // list-steps-icons
    .step-icon{
        .step-num{
            span{
                font-size: 14px;
            }
        }
        .step-text{
            font-size: 15px;
        }
    }

    // List menu 2
    ul.list-menu-2{
        > li{
            padding: 18px 10px;
        }
        a{
            font-size: 13px;
        }
    }
}


@include media-breakpoint-down(lg) {
    // list-steps-icons
    .step-icon{
        .step-num{
            span{
                font-size: 13px;
            }
        }
        .step-text{
            font-size: 13px;
        }
    }

    // List menu 2
    ul.list-menu-2{
        padding-top: 10px;
        padding-bottom: 10px;
        > li{
            padding: 8px 10px;
        }
        a{
            font-size: 13px;
        }
    }
}


@include media-breakpoint-down(md) {
    // list boxed
    ul.list-boxed{
        li{
            padding: 30px 15px;
        }
        h5{
            margin-bottom: 30px;
        }
    }

    // list boxed icons
    ul.list-boxed-icons li {
        padding: 30px 15px;
    }

    //boxed item
    .boxed-item{
        padding: 30px 15px;
    }

    .boxed-icon-item{
        padding: 30px 15px;
    }

    ul.list-grid-images-small {
        margin-left: -15px;
        margin-right: -15px;
        li {
            flex: 1 1 100%;
            padding: 0px;
            margin: 5px 0px;
        }
    }
}

@include media-breakpoint-down(sm) {
    // list menu
    ul.list-menu{
        display: block;
        li {
            padding: 5px;
            border-bottom: 1px solid $gray-300;
        }
        li:first-child{
            padding-left: 5px;
        }
    }
    // list boxed-icons
    ul.list-boxed-icons{
        .list-boxed-item{
            .boxed-icon{
                flex: 0 0 80px;
                font-size: 56px;
            }
        }
    }

    .boxed-icon-item{
        .list-boxed-item{
            .boxed-icon{
                flex: 0 0 80px;
                img{
                    max-width: 62px;
                }
            }
        }
    }

    // list grid-images
    ul.list-grid-images{
        li{
            flex: 1 1 50%;
            padding: 15px 20px;
            border:0px;
        }
        li:nth-child(odd){
            border-right: 1px solid #dadada;
        }
    }

    // download list
    .download-item{
        display: block;
        text-align: right;
        .download-text{
            text-align: left;
        }
        .download-link{
            display: inline-flex;
            justify-content: flex-end;
        }
    }
    // list-steps-icons
    ul.list-steps-icons{
        flex-wrap: wrap;
        li{
            flex:  0 0 100%;
            padding: 0px 25%;
            margin-bottom: 40px;
        }
    }
    .step-icon:after{
        top: 100%;
        right: 50%;
        transform: translate(50%, -50%) rotate(90deg);
    }
}
